html, body { overflow-x: hidden; }
@import "settings";
@import "mixins";

/*****************************************************************************
min-width: 1430px
*****************************************************************************/
@media only screen and (min-width: 1430px) {

	.container {
		max-width: 1430px;
	}

}

/*****************************************************************************
1700px - 1890px
*****************************************************************************/
@media only screen and (min-width: 1700px) and (max-width: 1890px) {

	#slider-main-content .slider-main .slide .photo {
		right: -94px;
	}
	#why-us .inside .left-col {
		margin-right: 60px;
	}

}

/*****************************************************************************
max-width: 1699px
*****************************************************************************/
@media only screen and (max-width: 1699px) {

	#Header .header-content {
		.left-col {
			display: flex;
			align-items: center;
			padding-top: 0;
			a.logo {
				width: 180px;
				height: 99px;
			}
		}
		.right-col .menu-bar a.valuation {
			padding: 0 50px;
		}
	}

	#menu > ul > li > a {
		padding: 0 20px;
	}

	#slider-main-content .slider-main .slide {
		.photo {
			width: 1023px;
			height: 500px;
		}
		.content-wrapper {
			max-width: 1380px;
			.desc {
				width: 382px;
				height: 500px;
				padding-top: 100px;
				h5 {
					font-size: 21px;
					line-height: 21px;
				}
				h2 {
					font-size: 36px;
				}
			}
		}
	}

	#offer-main {
		width: 92%;
		.items {
			height: 180px;
			.item {
				&:after {
					height: 128px;
				}
				a {
					font-size: 26px;
					line-height: 28px;
				}
			}
		}
	}

	#why-us {
		background-size: 25%;
		.inside .left-col {
			margin-right: 100px;
		}
	}

	#contact-page-map .address-box {
		right: 57%;
	}

}


/*****************************************************************************
max-width: 1429px
*****************************************************************************/
@media only screen and (max-width: 1429px) {

	#Header {
		padding: 0 30px;
		.header-content {
			.left-col {
				padding-right: 30px;
				a.logo {
					width: 140px;
					height: 77px;
				}
			}
			.right-col {
				.top-bar {
					.phone p.text {
						margin-right: 20px;
					}
					.email {
						margin-left: 40px;
					}
					.like-this {
						margin-left: 27px;
						padding-left: 38px;
					}
				}
				.menu-bar a.valuation {
					margin-left: 20px;
					padding: 0 35px;
				}
			}
		}
	}

	#menu > ul > li > a {
		padding: 0 17px;
	}

	#slider-main-content .slider-main .slide {
		.photo {
			width: 819px;
			height: 400px;
		}
		.content-wrapper {
			max-width: 1110px;
			.desc {
				width: 335px;
				height: 400px;
				padding-top: 70px;
				h5 {
					margin-bottom: 30px;
					font-size: 20px;
					line-height: 20px;
				}
				h2 {
					font-size: 28px;
				}
			}
		}
	}

	#offer-main {
		.items {
			height: 160px;
			.item {
				&:after {
					height: 108px;
				}
				a {
					font-size: 22px;
					line-height: 24px;
				}
			}
		}
	}

	#about-us {
		padding: 90px 0 62px;
		.inside {
			.left-col {
				margin-right: 30px;
			}
			.right-col {
				width: 700px;
				h2 {
					font-size: 38px;
					line-height: 38px;
				}
				h3 {
					font-size: 38px;
					line-height: 38px;
				}
				p {
					font-size: 20px;
					line-height: 30px;
					br {
						display: none;
					}
				}
			}
		}
		.icons {
			margin-top: 90px;
		}
	}

	#projects {
		background-size: 10px 418px;
		.inside {
			padding-bottom: 70px;
			h3 {
				font-size: 32px;
			}
			.slide a .photo {
				height: 339px;
			}
			.bx-controls-direction {
				bottom: -60px;
			}
			.line {
				margin-top: 52px;
			}
		}
	}

	#why-us {
		margin-bottom: 90px;
		padding-top: 80px;
		background: none;
		.inside {
			.left-col {
	      margin-right: 90px;
	    }
			.right-col {
				h2 {
					padding-bottom: 25px;
					font-size: 34px;
				}
			}
			.name-tabs {
				margin-top: 100px;
			}
		}
	}

	#partners .inside {
		padding: 34px 0 50px;
	}

	.text-line h3 {
		font-size: 34px;
		line-height: 34px;
	}

	#opinions-news {
		padding-bottom: 78px;
		.inside {
			h3 {
				font-size: 26px;
			}
			.left-col {
				width: 370px;
				.opinions {
					min-height: 305px;
				}
			}
			.right-col {
				width: 700px;
				.items .item {
					width: 340px;
					a {
						.photo {
							margin-bottom: 18px;
							width: 340px;
							height: 208px;
						}
						h5 {
							margin-bottom: 12px;
							font-size: 17px;
							line-height: 24px;
						}
					}
				}
			}
		}
	}

	#free {
		margin-bottom: 75px;
		.inside {
			padding: 0 0 0 30px;
			h3 {
				font-size: 40px;
			}
		}
	}

	#contact-home {
		padding-bottom: 90px;
		.info {
			padding-left: 630px;
			background-position: 0 0;
		}
	}

	#Footer {
		padding: 40px 0 0;
		.signature p {
			font-size: 15px;
		}
	}

	.subpage-simple {
    margin: 40px 0 90px;
	}

	#contact-page-map {
		.city {
			width: 690px;
		}
		.address-box {
			width: 550px;
			height: 380px;
			top: 93px;
			right: 59%;
			padding: 93px 90px 0;
		}
	}

	.subpage-contact {
		padding-bottom: 80px;
		.contact-info {
			.left-col {
				width: 440px;
			}
			.right-col {
				width: 600px;
			}
		}
	}

	.subpage-blog {
		padding-top: 80px;
		.inside {
			padding: 0;
			.item {
				width: 350px;
				a .photo {
					height: 214px;
					margin-bottom: 25px;
				}
			}
		}
	}

	.subpage-blog-details {
		margin: 60px 0 90px;
		.inside {
			.blog-details {
		    width: 67%;
				.blog-details__header {
					margin-bottom: 30px;
					h3 {
						padding-bottom: 20px;
						font-size: 26px;
						line-height: 30px;
					}
				}
				img {
					margin: 20px 0;
				}
			}
			.blog-sidebar {
				width: 30%;
				aside {
					margin-bottom: 20px;
					h3 {
						margin-bottom: 30px;
						padding-bottom: 15px;
						font-size: 18px;
						line-height: 21px;
					}
				}
				.widget-latest-news {
					.items {
						.item a {
							.desc {
								width: 230px;
								p {
									&.title {
										font-size: 14px;
										line-height: 17px;
									}
								}
							}
						}
					}
				}

			}
		}
	}

	.subpage-offer .inside {
		.name-tabs h3.tab {
			margin: 0 20px;
			font-size: 20px;
		}
		.content-tabs .item {
			padding: 0 35px;
			.boxes .box {
				&:nth-child(4n) {
					margin-right: 32px;
				}
				&:nth-child(3n) {
					margin-right:0;
				}
			}
		}
	}

	.subpage-projects {
		padding-top: 80px;
		.inside .item {
			margin-right: 30px;
			margin-bottom: 40px;
			width: 350px;
			a .photo {
				height: 263px;
			}
		}
	}

	.subpage-with-photo {
		margin: 100px 0;
		.inside {
			.left-col {
				width: 520px;
				.photo {
					img {
						width: 450px;
						top: -22px;
						left: 30px;
					}
					&:before {
						width: 462px;
						height: 365px;
					}
				}
			}
			.right-col {
				width: 590px;
			}
		}
	}

	.subpage-offer-details .inside {
		.photo-big {
			width: 560px;
			height: 374px;
		}
		.right-col {
			width: 505px;
			h3 {
				margin-bottom: 40px;
				padding-bottom: 30px;
			}
			.manufacturer {
				margin-bottom: 40px;
				padding-bottom: 40px;
				h5 {
					margin-right: 80px;
				}
			}
		}
	}

}

/*****************************************************************************
max-width: 1199px
*****************************************************************************/
@media only screen and (max-width: 1199px) {

	#nav-icon {
		display: block;
	}
	#menu {
		display: none;
	}

	#Header {
		padding: 0 40px;
		.header-content {
			.left-col a.logo {
				width: 110px;
				height: 60px;
			}
			.right-col {
				.top-bar {
					display: none;
					.like-this {
						display: none;
					}
				}
				.menu-bar {
					border-top: 0;
				}
			}
		}
	}
	#Header.sticky .header-content .left-col a.logo {
		width: 110px;
		height: 60px;
	}

	#Content {
	    padding-top: 86px;
	}

	#slider-main-content .slider-main .slide {
		.photo {
			width: 614px;
			height: 300px;
		}
		.content-wrapper {
			max-width: 930px;
			.desc {
				width: 345px;
				height: 300px;
				padding-top: 40px;
				h5 {
					margin-bottom: 20px;
					font-size: 18px;
					line-height: 18px;
				}
				h2 {
					margin-bottom: 25px;
					font-size: 26px;
					br {
						display: none;
					}
				}
			}
		}
	}

	#offer-main {
		.items {
			height: 140px;
			.item {
				&:after {
					height: 88px;
				}
				a {
					font-size: 20px;
					line-height: 22px;
				}
			}
		}
	}

	#about-us {
		padding: 80px 0 50px;
		.inside {
			.left-col {
				margin-right: 30px;
			}
			.right-col {
				width: 550px;
				h2 {
					font-size: 30px;
					line-height: 30px;
				}
				h3 {
					font-size: 30px;
					line-height: 30px;
				}
				p {
					font-size: 18px;
					line-height: 28px;
				}
			}
		}
		.icons {
			margin-top: 70px;
			.item {
				&.i1 {
					padding-left: 106px;
					background-size: 84px;
				}
				&.i2 {
					padding-left: 110px;
					background-size: 88px;
				}
				&.i3 {
					padding-left: 97px;
					background-size: 76px;
				}
			}
		}
	}

	#projects {
		background-size: 10px 345px;
		.inside {
			padding-bottom: 70px;
			h3 {
				font-size: 28px;
			}
			h5 {
				margin-bottom: 65px;
				font-size: 17px;
				line-height: 24px;
			}
			.slide a {
				.photo {
					height: 282px;
				}
				p {
					bottom: 20px;
					font-size: 16px;
					line-height: 16px;
				}
				&:hover {
					p {
						bottom: 40px;
					}
				}
			}
			.bx-controls-direction {
				bottom: -60px;
			}
			.line {
				margin-top: 52px;
			}
		}
	}

	#why-us {
		margin-bottom: 80px;
		padding-top: 70px;
		.inside {
			.left-col {
	      margin-right: 60px;
	    }
			.right-col {
				width: 580px;
				h2 {
					padding-bottom: 20px;
					font-size: 30px;
				}
			}
			.name-tabs {
				margin-top: 100px;
			}
		}
	}

	#partners {
		.inside {
			padding: 20px 0 30px;
		}
		.slider-partners-content .slide img {
			max-width: 80%;
		}
	}

	.text-line h3 {
		font-size: 30px;
		line-height: 30px;
	}

	#opinions-news {
		padding-bottom: 78px;
		.inside {
			h3 {
				font-size: 24px;
			}
			p.info {
				font-size: 15px;
			}
			.left-col {
				width: 310px;
				.opinions {
					min-height: inherit;
					padding-bottom: 20px;
				}
			}
			.right-col {
				width: 590px;
				.items .item {
					width: 285px;
					a {
						.photo {
							margin-bottom: 10px;
							width: 285px;
							height: 175px;
						}
						h5 {
							margin-bottom: 10px;
							font-size: 15px;
							line-height: 21px;
						}
					}
				}
			}
		}
	}

	#free {
		margin-bottom: 75px;
		.inside {
			padding: 0 0 0 20px;
			height: 160px;
			h3 {
				font-size: 36px;
			}
			h6 {
				font-size: 14px;
			}
			h5 {
				font-size: 18px;
			}
		}
	}

	#contact-home {
		padding-bottom: 70px;
		.info {
			padding-left: 445px;
			background-size: 420px;
			min-height: inherit;
			h3 {
				padding-top: 24px;
				font-size: 28px;
			}
			h4 {
				font-size: 20px;
				line-height: 24px;
				&.tel {
					margin-top: inherit;
				}
			}
			h5 {
				margin-bottom: 20px;
				font-weight: 300;
				font-size: 17px;
			}
		}
	}

	#Footer .menu .inside .menu-list li {
		padding: 0 25px;
	}

	.subpage-simple {
    margin: 30px 0 80px;
	}

	#contact-page-map {
		height: 480px;
		.city {
			width: 610px;
		}
		#map {
	    height: 480px;
		}
		.address-box {
			padding: 55px 50px 0;
			width: 420px;
			height: 280px;
			right: 67%;
			h4 {
				font-size: 20px;
				line-height: 22px;
			}
			p {
				font-size: 17px;
				line-height: 22px;
			}
		}
	}

	.subpage-contact .contact-info {
		padding-top: 60px;
		.inside {
			justify-content: center;
		}
		.left-col {
			display: none;
		}
		.right-col {
			h3 {
				margin-bottom: 25px;
				font-size: 24px;
			}
		}
	}

	.subpage-blog {
		padding-top: 65px;
		.inside {
			.item {
				width: 293px;
				margin-right: 20px;
				a {
					.photo {
						height: 179px;
						margin-bottom: 20px;
					}
					h5 {
						font-size: 17px;
						line-height: 23px;
					}
				}
			}
		}
	}

	.subpage-blog-details {
		margin: 50px 0 60px;
		.inside {
			.blog-details {
				.blog-details__header {
					margin-bottom: 30px;
					h3 {
						padding-bottom: 20px;
						font-size: 22px;
						line-height: 28px;
					}
				}
			}
			.blog-sidebar {
				aside {
					h3 {
						margin-bottom: 30px;
						padding-bottom: 10px;
						font-size: 16px;
						line-height: 20px;
					}
				}
				.widget-latest-news .items .item a {
					padding-bottom: 12px;
					.desc {
						width: 177px;
					}
				}
			}
		}
	}

	.subpage-offer {
		padding-bottom: 50px;
		.inside {
			.name-tabs {
				margin-bottom: 50px;
				h3.tab {
					margin: 0 15px;
					font-size: 18px;
				}
			}
			.content-tabs .item {
				padding: 0;
				.boxes .box {
					margin-right: 20px;
					margin-bottom: 30px;
					width: 296px;
					&:nth-child(4n) {
						margin-right: 20px;
					}
					a {
						.photo {
							height: 210px;
						}
						.desc {
							h4 {
								font-size: 20px;
							}
							h5 {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}

	.subpage-projects {
		padding-top: 65px;
		.inside .item {
			margin-right: 20px;
			margin-bottom: 30px;
			width: 296px;
			a .photo {
				height: 223px;
			}
		}
	}

	.subpage-with-photo {
		margin: 90px 0;
		.inside {
			.left-col {
				width: 310px;
				.photo {
					img {
						width: 310px;
						top: -20px;
						left: 20px;
						@include box-shadow(0 15px 20px rgba(23, 23, 24, 0.3));
					}
					&:before {
						width: 310px;
						height: 250px;
					}
				}
			}
			.right-col {
				width: 575px;
			}
		}
		&.different-typography article h5 a {
			padding: 10px 0;
		}
	}

}


/*****************************************************************************
max-width: 991px
*****************************************************************************/
@media only screen and (max-width: 991px) {

	.responsive-block-open #Wrapper {
		left: 0;
	}
	#responsive-block {
		left: -260px;
		width: 260px;
	}

	#Header {
		padding: 0 30px;
		.header-content .right-col .menu-bar {
			height: 80px;
		}
	}

	#Content {
	    padding-top: 80px;
	}

	#slider-main-content .slider-main .slide {
		.photo {
			width: 409px;
			height: 200px;
		}
		.content-wrapper {
			max-width: 690px;
			.desc {
				width: 320px;
				height: 200px;
				padding-top: 20px;
				h5 {
					margin-bottom: 20px;
					font-size: 16px;
					line-height: 16px;
				}
				h2 {
					margin-bottom: 15px;
					font-size: 24px;
				}
			}
		}
	}

	#offer-main {
		margin-top: 30px;
		.items {
			height: 120px;
			.item {
				&:after {
					height: 68px;
				}
				a {
					font-size: 18px;
					line-height: 20px;
					font-weight: 500;
				}
			}
		}
	}

	#about-us {
		padding: 60px 0 30px;
		.inside {
			.left-col {
				margin-right: 25px;
			}
			.right-col {
				width: 400px;
				h2 {
					font-size: 26px;
					line-height: 26px;
				}
				h3 {
					margin-bottom: 25px;
					font-size: 26px;
					line-height: 26px;
				}
				p {
					margin-bottom: 25px;
					font-size: 16px;
					line-height: 24px;
				}
			}
		}
		.icons {
			margin-top: 50px;
			.item {
				font-size: 16px;
				font-weight: 400;
				&.i1 {
					padding-left: 70px;
					background-size: 54px;
				}
				&.i2 {
					padding-left: 78px;
					background-size: 58px;
				}
				&.i3 {
					padding-left: 64px;
					background-size: 46px;
				}
			}
		}
	}

	#projects {
		background-size: 10px 323px;
		.inside {
			padding-bottom: 60px;
			h3 {
				font-size: 24px;
			}
			h5 {
				margin-bottom: 65px;
				font-size: 16px;
				line-height: 22px;
			}
			.slide a {
				.photo {
					height: 280px;
				}
			}
			.bx-controls-direction {
				bottom: -53px;
			}
			.line {
				margin-top: 45px;
			}
		}
	}

	#why-us {
		margin-bottom: 70px;
		padding-top: 60px;
		h2.mobile {
			display: block;
		}
		.inside {
			.left-col {
	      margin-right: 50px;
	    }
			.right-col {
				width: 55%;
				margin-top: 8px;
				h2 {
					display: none;
				}
			}
			.name-tabs {
				margin-top: 0;
				h3.tab {
					padding: 10px 0;
					font-size: 14px;
					line-height: 16px;
				}
			}
			.content-tabs .item p {
				br {
					display: none;
				}
			}
		}
	}

	#partners {
		.inside {
			padding: 10px 0 20px;
		}
		.slider-partners-content .slide img {
			max-width: 80%;
		}
	}

	.text-line h3 {
		font-size: 26px;
		line-height: 26px;
	}

	#opinions-news {
		padding: 50px 0 70px;
		.inside {
			flex-wrap: wrap;
			h3 {
				font-size: 26px;
			}
			p.info {
				margin-bottom: 40px;
				font-size: 17px;
			}
			.left-col {
				width: 100%;
				margin-bottom: 50px;
				.opinions {
					padding-bottom: 40px;
				}
			}
			.right-col {
				width: 100%;
				.items .item {
					width: 335px;
					a {
						.photo {
							margin-bottom: 10px;
							width: 335px;
							height: 205px;
						}
						h5 {
							margin-bottom: 12px;
							font-size: 17px;
							line-height: 24px;
						}
					}
				}
			}
		}
	}

	#free {
		margin-bottom: 70px;
		.inside {
			padding: 0;
			height: 140px;
			h3 {
				font-size: 30px;
			}
			h6 {
				font-size: 14px;
			}
			h5 {
				font-size: 16px;
			}
		}
	}

	#contact-home {
		padding-bottom: 70px;
		.info {
			padding-left: 390px;
			background-size: 360px;
			h3 {
				padding-top: 24px;
				font-size: 28px;
			}
			h4 {
				font-size: 20px;
				line-height: 24px;
			}
			h5 {
				margin-bottom: 20px;
				font-weight: 300;
				font-size: 17px;
			}
		}
	}

	#Footer {
		padding-top: 30px;
		.social-links .inside .social-icons p {
			display: none;
		}
		.menu .inside .menu-list li {
			padding: 0 15px;
			font-size: 16px;
		}
	}

	.subpage-simple {
    margin: 25px 0 70px;
	}

	#contact-page-map {
		height: 340px;
		.city {
			display: none;
		}
		#map {
			height: 340px;
			width: 100%;
		}
		.address-box {
			display: none;
		}
	}

	.subpage-contact .contact-info {
		padding-top: 50px;
		.inside {
			flex-wrap: wrap;
		}
		.left-col {
			display: block;
			width: 100%;
			background: none;
			padding: 0;
			margin-bottom: 50px;
			.item {
				display: block;
			}
		}
		.right-col {
			width: 100%;
		}
	}

	.subpage-blog {
		padding: 60px 0 40px;
		.inside {
			justify-content: space-between;
			.item {
				width: 330px;
				margin-right: 0;
				margin-bottom: 40px;
				a {
					.photo {
						height: 202px;
						margin-bottom: 10px;
					}
					h5 {
						margin-bottom: 8px;
					}
				}
			}
		}
	}

	.subpage-blog-details {
		margin: 40px 0;
		.inside {
			flex-wrap: wrap;
			.blog-details {
				width: 100%;
			}
			.blog-sidebar {
				width: 100%;
				.widget-latest-news .items .item a {
					padding-bottom: 15px;
					margin-bottom: 15px;
					justify-content: flex-start;
					.photo {
						width: 85px;
						margin-right: 20px;
					}
					.desc {
						width: inherit;
						flex: 1;
						p.date {
							margin-top: 10px;
						}
					}
				}
			}
		}
	}

	.subpage-offer {
		padding-bottom: 40px;
		.inside {
			.name-tabs {
				margin-bottom: 50px;
				h3.tab {
					margin: 0 20px;
					padding-bottom: 18px;
					font-size: 16px;
				}
			}
			.content-tabs .item {
				padding: 0 4px;
				.boxes .box {
					margin-right: 32px;
					margin-bottom: 35px;
					width: 325px;
					&:nth-child(4n) {
						margin-right: 32px;
					}
					&:nth-child(3n) {
						margin-right: 32px;
					}
					&:nth-child(2n) {
						margin-right: 0;
					}
					a {
						.photo {
							height: 231px;
						}
						.desc {
							h4 {
								font-size: 20px;
							}
							h5 {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}

	.subpage-projects {
		padding: 60px 0 40px;
		.inside {
			justify-content: space-between;
			.item {
				margin-right: 0;
				margin-bottom: 30px;
				width: 330px;
				a .photo {
					height: 248px;
				}
			}
		}
	}

	.subpage-with-photo {
		margin: 40px 0 70px;
		.inside {
			flex-wrap: wrap;
			.left-col {
				order: 2;
				width: 520px;
				margin: 0 auto;
				.photo {
					img {
						width: 450px;
						top: -22px;
	          left: 30px;
						@include box-shadow(0 15px 20px rgba(23, 23, 24, 0.3));
					}
					&:before {
						width: 462px;
	          height: 365px;
					}
				}
			}
			.right-col {
				order: 1;
				width: 100%;
				margin-bottom: 30px;
			}
		}
		&.different-typography article h5 a {
			padding: 0;
		}
	}

}

/*****************************************************************************
max-width: 767px
*****************************************************************************/
@media only screen and (max-width: 767px) {

	#Header .header-content .right-col .menu-bar a.valuation {
		padding: 0 20px;
	}

	#slider-main-content .slider-main .slide {
		.photo {
			width: 360px;
			height: 176px;
		}
		.content-wrapper {
			max-width: 510px;
			.desc {
				width: 182px;
				height: 176px;
				padding-top: 20px;
				h5 {
					margin-bottom: 10px;
					font-size: 14px;
					line-height: 14px;
				}
				h2 {
					margin-bottom: 10px;
					font-size: 18px;
				}
			}
		}
	}

	#offer-main {
		margin-top: 30px;
		.items {
			height: 100px;
			.item {
				&:after {
					top: 20px;
					height: 60px;
				}
				a {
					font-size: 16px;
					line-height: 18px;
					font-weight: 400;
				}
			}
		}
	}

	#about-us {
		padding: 50px 0 10px;
		.inside {
			flex-direction: column;
			align-items: center;
			.left-col {
				display: none;
			}
			.right-col {
				width: 100%;
				h2 {
					font-size: 26px;
					line-height: 26px;
				}
				h3 {
					margin-bottom: 25px;
					font-size: 26px;
					line-height: 26px;
				}
				p {
					margin-bottom: 25px;
					font-size: 18px;
					line-height: 28px;
				}
			}
		}
		.icons {
			margin-top: 10px;
			justify-content: space-between;
			.item {
				font-size: 14px;
				font-weight: 300;
				&.i1 {
					padding-left: 50px;
					background-size: 40px;
				}
				&.i2 {
					padding-left: 53px;
					background-size: 42px;
				}
				&.i3 {
					padding-left: 48px;
					background-size: 40px;
				}
			}
		}
	}

	#projects {
		background-size: 10px 323px;
		.inside {
			padding: 40px 0 50px;
			h3 {
				font-size: 20px;
			}
			h5 {
				margin-bottom: 40px;
				font-size: 14px;
				line-height: 20px;
			}
			.slide a {
				.photo {
					height: 315px;
				}
			}
			.bx-controls-direction {
				bottom: -48px;
			}
			.line {
				margin-top: 40px;
			}
		}
	}

	#why-us {
		margin-bottom: 65px;
		padding-top: 45px;
		h2.mobile {
			font-size: 22px;
		}
		.inside {
			.left-col {
	      margin-right: 40px;
	    }
			.right-col {
				width: 58%;
				margin-top: 8px;
			}
			.name-tabs {
				align-items: flex-start;
				h3.tab {
					padding: 10px 0;
					font-size: 14px;
					line-height: 16px;
				}
			}
			.content-tabs .item p {
				line-height: 28px;
			}
		}
	}

	#partners {
		.inside {
			padding: 0;
		}
	}

	.text-line h3 {
		font-size: 22px;
		line-height: 22px;
	}

	#opinions-news {
		padding: 40px 0 55px;
		.inside {
			h3 {
				font-size: 24px;
			}
			p.info {
				margin: 12px 0 35px;
				font-size: 17px;
			}
			.left-col {
				margin-bottom: 50px;
				.opinions {
					padding-bottom: 30px;
					.slide {
						.top {
							margin-bottom: 5px;
						}
						p {
							font-size: 16px;
							line-height: 24px;
						}
					}
				}
			}
			.right-col {
				.items .item {
					width: 250px;
					a {
						.photo {
							margin-bottom: 10px;
							width: 250px;
							height: 153px;
						}
						h5 {
							margin-bottom: 12px;
							font-size: 15px;
							line-height: 21px;
						}
					}
				}
			}
		}
	}

	#free {
		margin-bottom: 20px;
		background-size: 100% 177px;
		.inside {
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			padding: 0;
			height: 150px;
			h3 {
				margin-bottom: 10px;
				font-size: 26px;
			}
			h6 {
				margin-bottom: 10px;
				font-size: 14px;
			}
			h5 {
				font-size: 16px;
			}
		}
	}

	#contact-home {
		padding-bottom: 60px;
		.info {
			background: none;
			padding-left: 0;
		}
	}

	#Footer {
		padding-top: 0;
		.social-links .inside {
			height: inherit;
			padding: 20px 0;
		}
		.menu .inside {
			height: 90px;
			.menu-list li {
				padding: 0 8px;
				font-size: 14px;
			}
		}
		.signature .inside {
			flex-direction: column;
			justify-content: center;
			height: inherit;
			padding: 20px 0;
			text-align: center;
		}
	}

	.subpage-simple {
    margin: 25px 0 60px;
	}

	#contact-page-map {
		height: 300px;
		#map {
			height: 300px;
		}
	}

	.subpage-contact {
		padding-bottom: 70px;
		.contact-info {
			padding-top: 50px;
			.left-col {
				margin-bottom: 50px;
				.item {
					h3 {
						font-size: 24px;
					}
					p {
						font-size: 17px;
					}
					h4 {
						font-size: 19px;
						line-height: 26px;
						&.tel {
							margin-top: 30px;
						}
					}
				}
			}
			.right-col {
				h3 {
					margin-bottom: 20px;
					font-size: 22px;
				}
			}
		}
	}

	.subpage-blog {
		padding: 40px 0 30px;
		.inside {
			.item {
				width: 245px;
				margin-bottom: 30px;
				a {
					.photo {
						height: 150px;
						margin-bottom: 10px;
					}
					h5 {
						margin-bottom: 6px;
						font-size: 15px;
						line-height: 21px;
					}
				}
			}
		}
	}

	.subpage-blog-details .inside {
		.blog-details {
			.blog-details__header {
				margin-bottom: 20px;
				h3 {
					font-size: 18px;
					line-height: 22px;
				}
			}
		}
	}

	.subpage-offer {
		padding-bottom: 35px;
		.inside {
			.name-tabs {
				margin: 38px 0;
				h3.tab {
					margin: 0 10px;
					padding-bottom: 15px;
					font-size: 14px;
				}
			}
			.content-tabs .item {
				padding: 0;
				.boxes .box {
					margin-right: 20px;
					margin-bottom: 20px;
					width: 245px;
					&:nth-child(4n) {
						margin-right: 20px;
					}
					&:nth-child(3n) {
						margin-right: 20px;
					}
					&:nth-child(2n) {
						margin-right: 0;
					}
					a {
						.photo {
							height: 174px;
						}
						.desc {
							padding: 25px 15px;
							h4 {
								font-size: 18px;
							}
							h5 {
								font-size: 15px;
							}
						}
					}
				}
			}
		}
	}

	.subpage-projects {
		padding: 40px 0 30px;
		.inside {
			.item {
				margin-bottom: 25px;
				width: 245px;
				a .photo {
					height: 184px;
				}
			}
		}
	}

}

/*****************************************************************************
max-width: 575px
*****************************************************************************/
@media only screen and (max-width: 575px) {

	#responsive-block {
		width: 250px;
		left: -250px;
	}

	#Header, #Header.sticky {
    padding: 0 15px;
		.header-content {
			.left-col a.logo {
				width: 90px;
				height: 49px;
			}
			.right-col .menu-bar {
				height: 70px;
				a.valuation {
					display: none;
				}
			}
		}
	}

	#Content {
    padding-top: 70px;
	}

	#slider-main-content .slider-main .slide {
		.photo {
			left: 0;
			right: 0;
			width: 100%;
			height: 235px;
			overflow: hidden;
			&:after {
				display: none;
			}
			img {
				max-width: 100%;
			}
		}
		.content-wrapper {
			max-width: 100%;
			.desc {
				padding: 20px 15px;
				width: 100%;
				height: inherit;
				margin-top: 235px;
			}
		}
	}

	#offer-main {
		margin-top: 30px;
		.items {
			flex-wrap: wrap;
			justify-content: space-between;
			height: inherit;
			background-color: none;
			@include border-radius(0);
			box-shadow: 0 0 0;
			.item {
				position: inherit;
				width: 49%;
				margin-bottom: 10px;
				background-color: #fff;
				@include border-radius(10px);
				box-shadow: 0 0 6px rgba(23, 23, 23, 0.2);
				&:after {
					display: none;
				}
				a {
					padding: 12px;
					font-size: 16px;
					line-height: 18px;
					font-weight: 400;
				}
				&:last-child {
					width: 100%;
				}
			}
		}
	}

	#about-us {
		padding: 35px 0 10px;
		.inside {
			.right-col {
				h2 {
					font-size: 24px;
					line-height: 24px;
				}
				h3 {
					margin-bottom: 25px;
					font-size: 24px;
					line-height: 24px;
				}
				p {
					margin-bottom: 25px;
					font-size: 17px;
					line-height: 26px;
				}
			}
		}
		.icons {
			margin-top: 10px;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			.item {
				width: 60%;
				height: 90px;
				font-size: 16px;
				font-weight: 400;
				&.i1 {
					padding-left: 80px;
					background-size: 54px;
				}
				&.i2 {
					padding-left: 80px;
					background-size: 58px;
				}
				&.i3 {
					padding-left: 80px;
					background-size: 54px;
				}
			}
		}
	}

	#projects {
		background-size: 10px 323px;
		.inside {
			padding: 40px 0 50px;
			h3 {
				font-size: 19px;
			}
			h5 {
				margin-bottom: 35px;
				font-size: 16px;
				line-height: 22px;
			}
			.slide a {
				.photo {
					height: 276px;
				}
			}
			.bx-controls-direction {
				bottom: -48px;
			}
			.line {
				margin-top: 40px;
			}
		}
	}

	#why-us {
		margin-bottom: 50px;
		padding-top: 40px;
		h2.mobile {
			margin-bottom: 30px;
			padding-bottom: 15px;
			font-size: 20px;
		}
		.inside {
			.left-col {
	      margin-right: 22px;
	    }
			.name-tabs {
			}
			.content-tabs .item {
				h3 {
					font-size: 15px;
				}
				p {
					line-height: 26px;
				}
			}
		}
	}

	#partners {
		.slider-partners-content .slide {
			height: 120px;
		}
	}

	.text-line h3 {
		font-size: 20px;
		line-height: 20px;
	}

	#opinions-news {
		padding: 35px 0 45px;
		.inside {
			h3 {
				font-size: 22px;
			}
			p.info {
				margin: 12px 0 30px;
				font-size: 16px;
			}
			.left-col {
				margin-bottom: 40px;
				.opinions {
					padding-bottom: 20px;
					.slide {
						.top {
							margin-bottom: 0;
						}
						.star {
							margin-bottom: 20px;
						}
						p {
							font-size: 15px;
							line-height: 22px;
						}
					}
				}
			}
			.right-col {
				.items .item {
					width: 220px;
					a {
						.photo {
							margin-bottom: 10px;
							width: 220px;
							height: 135px;
						}
						h5 {
							margin-bottom: 10px;
							font-size: 14px;
							line-height: 18px;
							br {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	#free {
		margin-bottom: 15px;
		background-size: 100% 177px;
		.inside {
			align-items: center;
			height: 150px;
			h3 {
				margin-bottom: 8px;
				font-size: 24px;
				text-align: center;
			}
			h6 {
				margin-bottom: 10px;
				font-size: 12px;
			}
			h5 {
				font-size: 15px;
			}
		}
	}

	#contact-home {
		padding-bottom: 50px;
		.info h3 {
			font-size: 24px;
		}
	}

	#Footer {
		.menu .inside {
			height: inherit;
			padding: 20px 0;
			.menu-list {
				flex-wrap: wrap;
				width: 100%;
				li {
					width: 33.3%;
					a {
						display: block;
						width: 100%;
						padding: 8px 0;
						text-align: center;
					}
				}
			}
		}
		.signature {
			p {
				margin-bottom: 10px;
				line-height: 16px;
			}
			.text-right p {
				margin-bottom: 0;
			}
		}
	}

	.subpage-simple {
    margin: 20px 0 50px;
	}

	.subpage-contact {
	    padding-bottom: 50px;
	}

	.subpage-blog {
		padding: 30px 0 25px;
		.inside {
			.item {
				width: 48%;
				margin-bottom: 27px;
				a {
					.photo {
						height: 132px;
						margin-bottom: 5px;
					}
					h5 {
						margin-bottom: 6px;
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
	}

	.subpage-blog-details {
		margin: 35px 0 20px;
		.inside {
			.blog-details {
				.blog-details__header {
					margin-bottom: 15px;
					h3 {
						font-size: 16px;
						line-height: 20px;
					}
				}
			}
			.blog-sidebar {
				aside h3 {
					margin-bottom: 20px;
				}
				.widget-latest-news .items .item a .desc p.date {
					margin-top: 0;
				}
			}
		}
	}

	.subpage-offer {
		padding-bottom: 35px;
		.inside {
			margin-top: 30px;
			.name-tabs {
				display: none;
			}
			.content-tabs .item {
				display: block;
				margin-bottom: 25px;
				h3.name {
					display: block;
				}
				.boxes {
					justify-content: space-between;
					.box {
						margin-right: 0;
						margin-bottom: 20px;
						width: 48%;
						&:nth-child(4n),&:nth-child(3n) {
							margin-right: 0;
						}
						a {
							.photo {
								height: 154px;
							}
							.desc {
								padding: 25px 15px;
								h4 {
									font-size: 18px;
								}
								h5 {
									font-size: 15px;
								}
							}
						}
					}
				}
			}
		}
	}

	.subpage-projects {
		padding: 30px 0 25px;
		.inside {
			.item {
				margin-bottom: 25px;
				width: 48%;
				a .photo {
					height: 162px;
				}
			}
		}
	}

	.subpage-with-photo {
		margin: 35px 0 60px;
		.inside {
			.left-col {
				width: 100%;
				.photo {
					position: inherit;
					img {
						position: inherit;
						top: 0;
						left: 0;
						max-width: 100%;
						@include box-shadow(0 5px 10px rgba(23, 23, 24, 0.3));
					}
					&:before {
						display: none;
					}
				}
			}
			.right-col {
				margin-bottom: 20px;
			}
		}
	}

}

/*****************************************************************************
max-width: 479px
*****************************************************************************/
@media only screen and (max-width: 479px) {

	#slider-main-content .slider-main .slide {
		.photo {
			height: 205px;
		}
		.content-wrapper {
			.desc {
				padding: 20px 15px;
				margin-top: 205px;
			}
		}
	}

	#about-us .icons .item {
		width: 66%;
	}

	#projects {
		background-size: 10px 278px;
		.inside {
			h3 {
				font-size: 21px;
			}
			.slide a {
				.photo {
					height: 239px;
				}
			}
		}
	}

	#why-us .inside .name-tabs h3.tab {
		padding: 8px 0;
	}

	#opinions-news {
		padding: 35px 0 20px;
		.inside {
			h3 {
				font-size: 22px;
			}
			p.info {
				margin: 12px 0 30px;
				font-size: 16px;
			}
			.left-col {
				margin-bottom: 40px;
				.opinions {
					padding-bottom: 20px;
					.slide {
						.star {
							margin-bottom: 20px;
						}
						p {
							font-size: 15px;
							line-height: 22px;
						}
					}
				}
			}
			.right-col {
				.items {
					flex-direction: column;
					justify-content: flex-start;
					align-items: center;
					.item {
						width: 369px;
						margin-bottom: 30px;
						a {
							.photo {
								margin-bottom: 10px;
								width: 100%;
								height: 226px;
							}
							h5 {
								margin-bottom: 5px;
								font-size: 17px;
								line-height: 24px;
							}
						}
					}
				}
			}
		}
	}

	#free .inside {
		.left-col {
			text-align: center;
			h6 {
				width: 70%;
				margin: 0 auto;
			}
		}
	}

	#Footer .signature p br {
		display: block;
	}

	.subpage-contact {
    padding-bottom: 50px;
	}

	.subpage-blog {
		.inside {
			justify-content: center;
			.item {
				width: 369px;
				margin-bottom: 35px;
				a {
					.photo {
						height: 226px;
						margin-bottom: 10px;
					}
					h5 {
						margin-bottom: 8px;
						font-size: 17px;
						line-height: 24px;
					}
				}
			}
		}
	}

	.subpage-blog-details {
		margin: 25px 0 15px;
		.inside {
			.blog-details {
				.blog-details__header {
					margin-bottom: 15px;
				}
				img {
					margin: 15px 0;
				}
			}
			.blog-sidebar .widget-latest-news .items .item a .photo {
				margin-right: 15px;
			}
		}
	}

	.subpage-offer {
		padding-bottom: 10px;
		.inside .content-tabs .item {
			h3.name {
				font-size: 20px;
			}
			.boxes .box a .photo {
				height: 133px;
				p {
					display: none;
				}
			}
		}
	}

	.subpage-projects .inside .item a .photo {
		height: 141px;
	}

}

/*****************************************************************************
max-width: 419px
*****************************************************************************/
@media only screen and (max-width: 419px) {

	#slider-main-content .slider-main .slide {
		.photo {
			height: 156px;
		}
		.content-wrapper {
			.desc {
				padding: 20px 15px;
				margin-top: 156px;
			}
		}
	}

	#about-us {
		padding-top: 30px;
		.inside .right-col {
			h2, h3 {
				font-size: 20px;
				line-height: 20px;
			}
			p {
				margin-bottom: 20px;
				font-size: 16px;
				line-height: 24px;
			}
		}
		.icons .item {
			width: 90%;
		}
	}

	#projects .inside {
		padding: 30px 0 40px;
		h3 {
			font-size: 19px;
		}
		h5 {
			font-size: 14px;
			line-height: 18px;
		}
		.slide a .photo {
			height: 365px;
		}
	}

	#why-us {
		.inside {
			.left-col {
				display: none;
			}
			.right-col {
				margin-top: 0;
				width: 100%;
			}
			.content-tabs .item {
				display: block;
				margin-bottom: 30px;
				&:last-child {
					margin-bottom: 0;
				}
				h3 {
					margin-bottom: 10px;
					&:after {
						margin-top: 8px;
					}
				}
				p {
					line-height: 24px;
				}
			}
		}
	}

	#partners {
		.slider-partners-content .slide {
			height: 100px;
		}
	}

	#opinions-news {
		padding: 30px 0 15px;
		.inside {
			h3 {
				font-size: 20px;
			}
			a.more {
				font-size: 15px;
			}
			p.info {
				margin: 12px 0 25px;
				font-size: 15px;
			}
			.left-col {
				margin-bottom: 35px;
				.opinions {
					padding-bottom: 20px;
					.slide {
						.star {
							margin-bottom: 20px;
						}
						p {
							font-size: 15px;
							line-height: 22px;
						}
					}
				}
			}
			.right-col {
				.items {
					.item {
						width: 100%;
						margin-bottom: 25px;
						a {
							.photo {
								margin-bottom: 10px;
								height: 178px;
							}
							h5 {
								margin-bottom: 3px;
								font-size: 16px;
								line-height: 20px;
							}
						}
					}
				}
			}
		}
	}

	#free {
		margin-bottom: 10px;
		.inside {
			h3 {
				font-size: 22px;
			}
			.left-col h6 {
				width: 90%;
			}
		}
	}

	#contact-home {
		padding-bottom: 45px;
		.info h3 {
			font-size: 22px;
		}
	}

	#Footer {
		.social-links .inside {
			flex-direction: column;
		}
		.menu .inside {
			padding: 15px 0;
			.menu-list li {
				width: 100%;
			}
		}
	}

	.subpage-simple {
    margin: 20px 0 40px;
	}

	#contact-page-map {
		height: 280px;
		#map {
			height: 280px;
		}
	}

	.subpage-contact {
		padding-bottom: 40px;
		.contact-info {
			.left-col .item {
				padding-left: 20px;
				h3 {
					font-size: 20px;
				}
				p {
					margin-top: 15px;
				}
				h4 {
					font-size: 18px;
				}
			}
			.right-col h3 {
				margin-bottom: 15px;
				font-size: 20px;
			}
		}
	}

	.subpage-blog {
		.inside {
			.item {
				width: 290px;
				margin-bottom: 35px;
				a {
					.photo {
						height: 178px;
						margin-bottom: 10px;
					}
					h5 {
						margin-bottom: 8px;
						font-size: 16px;
						line-height: 22px;
					}
				}
			}
		}
	}

	.subpage-offer {
		padding-bottom: 0;
		.inside {
			margin-top: 25px;
			.content-tabs .item {
				h3.name {
					padding-bottom: 12px;
					font-size: 18px;
				}
				.boxes {
					justify-content: center;
					.box {
						width: 325px;
						a .photo {
							height: 206px;
						}
					}
				}
			}
		}
	}

	.subpage-projects .inside {
		justify-content: center;
		.item {
			width: 100%;
			a .photo {
				height: 100%;
			}
		}
	}

	.subpage-with-photo {
    margin: 30px 0 50px;
}

}
